<template>
  <div :class="`page page_cabinet`" id="app">
    <div class="page-wrapper">
      <page-header />
      <router-view />
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageFooter from './components/PageFooter.vue'
import PageHeader from './components/PageHeader.vue'

export default {
  components: { PageFooter, PageHeader },
  name: 'App',

  computed: {
    currentRouteName() {
      return this.$route.name
    }
  },
  mounted() {
    const path = this.$route.fullPath
    const isEN = path === '/en'
    const isRU = path === '/ru'

    if (isEN || isRU) {
      localStorage.setItem('loc', path.substring(1))
      this.$i18n.locale = localStorage.getItem('loc')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/main.scss';
</style>
