<template>
  <header class="header">
    <router-link to="/" class="logo">
      <img src="../assets/img/logo.svg" alt="logo" />
    </router-link>

    <div :class="['header__menu', { active: mobIsActive }]">
      <!-- <nav class="header__nav">
        <a href="https://avovpn.com/" v-html="$t('header.tarifs')" />
        <a href="https://avovpn.com/about" v-html="$t('header.promo')" />
        <a href="https://avovpn.com/about" v-html="$t('header.set')" />
      </nav> -->
      <lang-switcher />
      <button
        v-if="isAuth"
        class="header__btn header__btn-auth"
        @click="openModal"
      >
        И
      </button>
      <a
        v-else
        href="https://avologin.com"
        class="btn btn_sm header__btn"
        @click.prevent="isAuth = true"
      >
        <div class="btn__text">{{ $t('header.cabinet') }}</div>
      </a>
    </div>
    <div
      :class="['header__menu-backdrop', { active: mobIsActive }]"
      @click="closeMenu"
    />
    <button
      :class="['mob-menu', { active: mobIsActive }]"
      @click="toggleMenu"
    />

    <!-- Модалка пользователя -->

    <Modal :class="{ active: showProfModal }" @closeModal="closeModal">
      <div class="modal__title">
        <button disabled class="header__btn header__btn-auth">И</button>
        <span> Иван Иванов </span>
      </div>

      <div class="modal__btns">
        <router-link
          to="/settings"
          class="btn btn_sm modal__btn"
          @click.native="closeModal"
        >
          <svg
            width="29"
            height="28"
            viewBox="0 0 29 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="btn__icon"
          >
            <path
              d="M11.8747 14C11.8747 12.5502 13.0499 11.375 14.4997 11.375C15.9494 11.375 17.1247 12.5502 17.1247 14C17.1247 15.4497 15.9494 16.625 14.4997 16.625C13.0499 16.625 11.8747 15.4497 11.8747 14Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5158 3.77097C11.6596 -0.236971 17.3396 -0.236972 18.4834 3.77097C18.8378 5.01278 20.1136 5.74936 21.3663 5.43538C25.4092 4.42198 28.2492 9.34104 25.3501 12.3356C24.4519 13.2634 24.4519 14.7366 25.3501 15.6644C28.2492 18.6589 25.4092 23.578 21.3663 22.5646C20.1136 22.2506 18.8378 22.9872 18.4834 24.229C17.3396 28.2369 11.6596 28.2369 10.5158 24.229C10.1614 22.9872 8.88558 22.2506 7.63295 22.5646C3.59006 23.578 0.75004 18.6589 3.64911 15.6644C4.54735 14.7366 4.54735 13.2634 3.64911 12.3356C0.75004 9.34104 3.59006 4.42198 7.63295 5.43538C8.88558 5.74936 10.1614 5.01278 10.5158 3.77097ZM14.4997 9.62498C12.0834 9.62498 10.1247 11.5837 10.1247 14C10.1247 16.4162 12.0834 18.375 14.4997 18.375C16.9159 18.375 18.8747 16.4162 18.8747 14C18.8747 11.5837 16.9159 9.62498 14.4997 9.62498Z"
              fill="currentColor"
            />
          </svg>

          <span class="btn__text">{{ $t('modal.btn.profile') }}</span>
        </router-link>
        <router-link
          to="/logout"
          class="btn btn_sm modal__btn"
          @click.native="closeModal"
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="btn__icon"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.5183 13.0278C6.98136 13.0278 6.54608 13.4631 6.54608 14C6.54608 14.537 6.98136 14.9723 7.5183 14.9723L17.8887 14.9723L17.8887 17.8889C17.8887 19.0932 17.8887 19.6953 17.8089 20.1991C17.3696 22.9725 15.1945 25.1476 12.4211 25.5869C11.9173 25.6667 11.3152 25.6667 10.1109 25.6667C8.90663 25.6667 8.30449 25.6667 7.80067 25.5869C5.02729 25.1476 2.85217 22.9725 2.41291 20.1991C2.33312 19.6953 2.33312 19.0932 2.33312 17.8889L2.33312 10.1111C2.33312 8.90688 2.33312 8.30475 2.41291 7.80093C2.85217 5.02755 5.02729 2.85243 7.80067 2.41317C8.30449 2.33337 8.90663 2.33337 10.1109 2.33337C11.3152 2.33337 11.9173 2.33337 12.4211 2.41317C15.1945 2.85243 17.3696 5.02755 17.8089 7.80093C17.8887 8.30475 17.8887 8.90688 17.8887 10.1112L17.8887 13.0278L7.5183 13.0278ZM17.8887 13.0278L24.1245 13.0278C23.7 12.6096 23.0752 12.1123 22.1626 11.3887L19.8772 9.57667C19.4565 9.24307 19.3859 8.63156 19.7195 8.21082C20.0531 7.79008 20.6646 7.71944 21.0853 8.05304L23.4171 9.90189C24.2888 10.593 25.0081 11.1634 25.5206 11.6739C26.0465 12.1977 26.4824 12.7791 26.6008 13.5221C26.626 13.6804 26.6387 13.8401 26.6387 14C26.6387 14.16 26.626 14.3197 26.6008 14.4779C26.4824 15.2209 26.0465 15.8024 25.5206 16.3262C25.0081 16.8367 24.2888 17.407 23.4171 18.0982L21.0853 19.947C20.6646 20.2806 20.0531 20.21 19.7195 19.7893C19.3859 19.3685 19.4565 18.757 19.8772 18.4234L22.1626 16.6114C23.0752 15.8878 23.7 15.3905 24.1245 14.9723L17.8887 14.9723L17.8887 13.0278Z"
              fill="currentColor"
            />
          </svg>

          <span class="btn__text">{{ $t('modal.btn.logout') }}</span>
        </router-link>
      </div>
    </Modal>
  </header>
</template>

<script>
import LangSwitcher from './LangSwitcher.vue'
import Modal from '@/components/Modal.vue'

export default {
  components: { LangSwitcher, Modal },
  name: 'PageHeader',
  data() {
    return {
      showProfModal: false,
      mobIsActive: false,
      body: document.querySelector('body'),
      isAuth: false
    }
  },

  methods: {
    toggleMenu() {
      this.mobIsActive = !this.mobIsActive

      if (this.mobIsActive) {
        this.body.classList.add('menu-is-active')
      } else {
        this.body.classList.remove('menu-is-active')
      }
    },

    closeMenu() {
      this.body.classList.remove('menu-is-active')
      this.mobIsActive = false
    },

    openModal() {
      this.closeMenu()
      this.showProfModal = true
    },

    closeModal() {
      this.showProfModal = false
    }
  },

  mounted() {
    if (window.innerWidth < 1200) {
      const links = document.querySelectorAll('.header__menu a')
      const a = [...links]
      a.map(el => {
        el.addEventListener('click', () => {
          this.closeMenu()
        })
      })
    }
  }
}
</script>
