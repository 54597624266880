<template>
  <div>
    <div class="modal__overlay" @click="$emit('closeModal')" />
    <div :class="['modal', { modal_big: big }]">
      <button
        class="modal__close"
        :title="$t('modal.btn.close')"
        @click="$emit('closeModal')"
      />

      <div class="modal__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    big: {
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '/src/assets/styles/modal';
</style>
