<template>
  <footer class="footer">
    <nav class="page-wrapper">
      <ul class="footer__nav">
        <li class="footer__nav-item">
          <a href="https://avovpn.com/" v-html="$t('footer.instructions')" />
        </li>
        <li class="footer__nav-item">
          <a href="https://avovpn.com/rules" v-html="$t('footer.rules')" />
        </li>
        <li class="footer__nav-item">
          <a href="https://avovpn.com/policy" v-html="$t('footer.policy')" />
        </li>
        <li class="footer__nav-item">
          <a
            href="https://avovpn.com/contacts"
            v-html="$t('footer.contacts')"
          />
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter'
}
</script>
